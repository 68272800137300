import React from 'react'
import axios from "axios" //handles request
import * as qs from "query-string" //encodes data
import Layout from '../components/Layout'

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null }
  }
  handleSubmit(event) {
    event.preventDefault()
    const formData = {}
    Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))
    const axiosOptions = {
      url: this.props.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData)
    }
    axios(axiosOptions).then(() => {
      this.setState({ feedbackMsg: "Message submitted successfully!" })
      this.domRef.current.reset()
    }).catch(() => this.setState({ feedbackMsg: "Error: Message not submitted." }))
  }
  render() {
    return (
      <Layout>
        <section className="section custom-contact-section">
          <div className="container custom-contact-container">
            <div className="columns" >
              <div className="column">
                <div className="section">
                  <h2 className="title">
                    Contact our team
                  </h2>
                  <h3 className="subtitle">
                    Get help with general questions by emailing us directly: <span className="email">info@ipecore.com</span>
                  </h3>
                  <h4 className="subsubtitle">
                    {this.state.feedbackMsg}
                  </h4>
                  <form method="post" ref={this.domRef} name="contact" data-netlify="true" className="custom-contact-component" onSubmit={event => this.handleSubmit(event)}>

                    <input type="hidden" ref="form-name" name="form-name" value="contact" />

                    <input type="text" ref="name" name="name" id="name" className="input" placeholder="Your name" />
                    <input type="email" ref="email" name="email" id="email" className="input" placeholder="Email" />
                    <input type="tel" ref="phone" name="phone" id="phone" className="input" placeholder="Phone" />
                    <input type="text" ref="job" name="job" id="job" className="input" placeholder="Job Title" />
                    <input type="text" ref="country" name="country" id="country" className="input" placeholder="Country" />
                    <textarea rows="4" ref="comments" placeholder="Project details or questions" name="comments" className="input" maxLength="450" />
                    <input type="submit" className="button" value="Get in touch" />
                  </form>
                </div>
              </div>
            </div>
          </div>

        </section>
      </Layout>
    )
  }
}

export default ContactPage
