import React from "react";
import { Link } from "gatsby";
import logo from "../img/ipecore-icon-logo.svg"

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column left-footer">
                <section>
                  <ul className="menu-list">
                    <li>
                      <div className="navbar-bold fake-a">Products</div>
                    </li>
                    <li>
                      <Link
                        to="/products/l2-l3-managed-switch-core"
                        className="navbar-item sub-category"
                      >
                        L2/L3 Managed Switch Core
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item sub-category"
                        to="/products/hsr-prp-redbox"
                      >
                        HSR/PRP RedBox
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item sub-category"
                        to="/products/multi-gigabit-serdes-phy-pcie-gen4"
                      >
                        Multi-Gigabit SerDes PHY (PCIe Gen-4)
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column footer-web">
                <section>
                  <div className="navbar-logo-web">
                    <img
                      src={logo}
                      alt="IPECoreIcon"
                      style={{ width: "120px" }}
                    />
                  </div>
                </section>
              </div>
              <div className="column">
                <section>
                  <ul className="menu-list bottom-list">
                    <li>
                      <Link className="navbar-item navbar-bold" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item navbar-bold"
                        to="/technology"
                      >
                        Technology
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item navbar-bold" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column footer-mobile">
                <section>
                  <div className="navbar-logo-web">
                    <img
                      src={logo}
                      alt="IPECoreIcon"
                      style={{ width: "120px" }}
                    />
                  </div>
                </section>
              </div>
            </div>
            <section className="copyright-section">
              <div className="copyright">© 2019 IPECORE</div>
            </section>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
